@import "~normalize.css";
@import "partials/mixins";
@import "partials/global";
@import "partials/variables";
@import "partials/fonts";
@import "partials/layouts";
@import "partials/common";
@import "components/button";
@import "components/header";
@import "components/footer";
@import "pages/home";
@import "pages/subject-class";
@import "pages/detail";
