.detail {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100vh;


  .main {
    flex: 1;
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-bottom: 70px;


    .detail__video {
      display: block;
      width: 95%;
      height: auto;
      @include margin-auto();
    }

    .detail__gist {
      flex: 1;
      overflow: hidden;
      width: 95%;
      margin-top: 10px;
      border: none;
      @include margin-auto();
    }
  }
}