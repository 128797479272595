@mixin set_color($color) {
  .header h1 {
    background-color: $color;
  }

  .header__common {
    border-bottom: 2px solid $color;
  }

  .main .button {
    border: 2px solid $color;
  }
}

.header.header__common {
  padding-bottom: 5px;

  h1 {
    color: white;
    font-weight: 600;
    text-align: center;
    padding: 5px 0;
  }

  h2 {
    color: black;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    margin-top: 5px;
  }

  h3 {
    color: black;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    //margin-top: 5px;
  }

  h4 {
    color: black;
    font-size: 1.3rem;
    font-weight: 600;
    text-align: center;
    //margin-top: 5px;
  }
}

.main {
  margin-top: 20px;
  padding-bottom: 90px;
}

.subject-class,
.detail {
  .header {
    h1 {
      font-size: 1.8rem;
      color: white;
      font-weight: 600;
      text-align: center;
      padding: 5px 0;
    }
  }

  // 英語
  &.subject_1 {
    $color: map-get($colors, english);
    @include set_color($color);
  }

  // 数学
  &.subject_2 {
    $color: map-get($colors, math);
    @include set_color($color);
  }

  // 理科
  &.subject_3 {
    $color: map-get($colors, science);
    @include set_color($color);
  }

  // 社会
  &.subject_4 {
    $color: map-get($colors, society);
    @include set_color($color);
  }

  // 国語
  &.subject_5 {
    $color: map-get($colors, national);
    @include set_color($color);
  }
}