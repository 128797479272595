$header-heights: (
  pc: 70px,
  sp: 60px
);

$footer-heights: (
  pc-upper: 410px,
  pc-lower: 350px,
  sp-upper: 530px,
  sp-lower: 515px,
  sp-fixed: 45px,
);

/* BreakPoints
* https://www.tam-tam.co.jp/tipsnote/html_css/post10708.html
* https://getbootstrap.jp/docs/4.5/layout/overview/
*
*  How to use:
*  @include mq(sp) {
*    hoge: fuga
*   }
*/

$breakpoints: (
  "sp": "screen and (max-width: 576px)",
  "tb": "screen and (min-width: 577px) and (max-width: 991px)",
  "sp-tb": "screen and (max-width: 991px)",
  "tb-pc": "screen and (min-width: 577px)",
  "pc": "screen and (min-width: 992px)"
) !default;

@mixin mq($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}