.button {
  display: block;
  width: 85%;
  padding: 8px 0;
  margin-top: 15px;
  text-align: center;
  border-radius: 5px;
  @include margin-auto;
}

@mixin button-link($color, $font-size) {
  color: $color;
  font-size: $font-size;
  text-decoration: none;

  &:hover,
  &:active,
  &:focus {
    color: $color;
    opacity: 0.8;
    text-decoration: none;
  }

  &:visited {
    color: $color;
  }
}