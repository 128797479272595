.subject-class {
  .main {
    .button {
      @include button-link(map-get($colors, black), 2rem);
      background-color: map-get($colors, white);
      font-weight: 600;
    }
  }

}

.subject {
  .header__common {
    border-bottom: 0;
    padding-bottom: 0;
  }
}

.unit {
  .main{
    .button{
      font-size: 1.4rem;
    }
  }
}

.class {

}