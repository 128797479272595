footer {
  $hex_active: FFFFFF;
  $hex_inactive: DDDDDD;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: map-get($colors, navy);

  @include mq(pc) {
    width: 900px;
  }

  .footer__wrap {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    margin: 0 0 10px 0;
    padding: 0;
    height: 60px;


    // 高校
    &.school_type_0 {
      li {
        width: 16%;
      }

    }

    // 中学
    &.school_type_1 {
      li {
        width: 20%;
      }
    }


    li {
      margin-top: 5px;

      .footer__button {
        display: block;
        @include text-link(map-get($colors, white), 1rem);
        text-decoration: none;
        text-align: center;

        &.footer__active {
          .footer__text {
            color: #FFF;
          }
        }

        .footer__icon {
          display: block;
          width: 100%;
          height: 25px;
          background-position: center center;
          background-repeat: no-repeat;
          background-size: contain;
        }

        .footer__text {
          display: block;
          width: 100%;
          font-size: 1rem;
          color: #DDD;
        }

        &.footer__button--home {
          .footer__icon {
            @include icon_home($hex_inactive);
          }
          &.footer__active {
            .footer__icon {
              @include icon_home($hex_active);
            }
          }
        }

        &.footer__button--english {
          .footer__icon {
            @include icon_english($hex_inactive);
          }
          &.footer__active {
            .footer__icon {
              @include icon_english($hex_active);
            }
          }
        }

        &.footer__button--math {
          .footer__icon {
            @include icon_math($hex_inactive);
          }
          &.footer__active {
            .footer__icon {
              @include icon_math($hex_active);
            }
          }
        }

        &.footer__button--science {
          .footer__icon {
            @include icon_science($hex_inactive);
          }
          &.footer__active {
            .footer__icon {
              @include icon_science($hex_active);
            }
          }
        }

        &.footer__button--society {
          .footer__icon {
            @include icon_society($hex_inactive);
          }
          &.footer__active {
            .footer__icon {
              @include icon_society($hex_active);
            }
          }
        }

        &.footer__button--national {
          .footer__icon {
            @include icon_national($hex_inactive);
          }
          &.footer__active {
            .footer__icon {
              @include icon_national($hex_active);
            }
          }
        }
      }
    }
  }
}