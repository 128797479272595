.home {
  .header {
    padding-top: 30px;

    .header__logo {
      width: 50%;
      @include margin-auto();

      .header__logo__image {
        width: 100%;
        height: auto;
      }
    }

    .header__copy {
      text-align: center;
    }
  }

  .main {
    h2 {
      font-size: 1.8rem;
      position: relative;
      text-align: center;
      font-weight: 600;
      color: map-get($colors, white);
      background-color: map-get($colors, navy);
      padding: 10px 0;
      margin: 30px 0;
      width: 90%;

      @include margin-auto();
      @mixin pseudo() {
        $home_h2_pseudo: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEYAAABgCAMAAACXBfSdAAAAaVBMVEUAAAAAACocJlsbJlsaJFYVHUsNF0QcJVscJVscJFobJFkWH1IbJVkbJVocI1gcJFgbJFYAGjMaIVIYIlgcJVwbJVsdJVocJVscJVgbI1kZI1UZH1EUFDsAF0YfKWQcJVsdJ2EdJl4cJl3LeQEwAAAAHnRSTlMABfrkWB4S79DBky6on351bwpEPvLWu6uLX0gpDQvtvoHMAAABSUlEQVR4Aa2ZhWFDUQwDFWYoc/Sa/XcsagPdH+BC9sWWNTfxSGcGo2f70j1/GO19JTDahFNiJmt/Ahh9zMLpMFreewAYLaYeAEZze/SY93w7HWbyEEqBSeV0GKCOg9naQBWf7NFj8lOXmLuOEsxbSQnmaF8AjA6+Ehg91hw70gIwuklblhgtV4BEIy0Ao50Zib6akegZkegk4ioxT6F0mC0i0VJcwZTiCuYWkegOkGjbCMHUbRnMgZFoK61gamkFA3w7diutYABpBZM6BjB6gSR6hCQa4/AY/kPxXzH6gwPlxzcD35qMKCBtQRJllA78TujfHbA1oKMAMJgAtuGHNmqERAZaYLwGxAWuHoC4wLUMEBe4sh4KCr7OM+ECIC00eIm0+FCKj8j4wI6PD/kwk45WAXHBsTMSggORPFA17LmCOZ4wpxzmsPQNfrviIFX0hgUAAAAASUVORK5CYII=";
        content: "";
        background-image: url($home_h2_pseudo);
        background-size: cover;
        height: 100%;
        width: 10px;
        position: absolute;
        top: 0;
      }

      &:before {
        @include pseudo();
        left: -10px;
      }

      &:after {
        @include pseudo();
        transform: scaleX(-1);
        right: -10px;
      }
    }

    .button {
      @include button-link(map-get($colors, white), 1.6rem);
      position: relative;

      .button_text {
        font-weight: 600;
        font-size: 1.6rem;
      }

      &:before {
        content: '';
        background-size: contain;
        position: absolute;
        width: 30px;
        height: 30px;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
      }

      $icon_color: "FFFFFF";


      &.button__english {
        background-color: map-get($colors, english);

        &:before {
          @include icon_english($icon_color);
        }
      }

      &.button__math {
        background-color: map-get($colors, math);

        &:before {
          @include icon_math($icon_color);
        }
      }

      &.button__science {
        background-color: map-get($colors, science);

        &:before {
          @include icon_science($icon_color);
        }
      }

      &.button__society {
        background-color: map-get($colors, society);

        &:before {
          @include icon_society($icon_color);
        }
      }

      &.button__national {
        background-color: map-get($colors, national);

        &:before {
          @include icon_national($icon_color);
        }
      }
    }
  }

}