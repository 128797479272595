html, body{
  margin: 0;
  padding: 0;
}
body {
  background: map-get($colors, gray);
}

#app {
  background: map-get($colors, white);
  width: 100%;
  min-height: 100vh;
  position: relative;
  @include mq(pc) {
    width: 900px;
    @include margin-auto();
  }
}
