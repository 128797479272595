$serif: source-han-sans-japanese, 'Helvetica Neue', Helvetica, Arial, 游ゴシック体, Yu Gothic, YuGothic, Hiragino Kaku Gothic ProN, Meiryo, sans-serif;
$monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;

:root {
  --font-family-sans-serif: $serif;
  --font-family-monospace: $monospace;
}

body {
  font-family: $serif;
  font-weight: 400;
  font-style: normal;
}

html {
  font-size: 62.5%;
  font-feature-settings: "palt";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $serif;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  span {
    font-size:inherit;
  }
}

p,
div,
span {
  margin: 0;
  padding: 0;
  font-family: $serif;
  line-height: 2;
  font-size: 1.4rem;
}
